













import { Component, Prop, Vue } from 'vue-property-decorator'
import CheckboxBase from '@/components/atoms/CheckboxBase.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    CheckboxBase,
    ButtonBase,
  },
})
export default class ToggleButton extends Vue {
  @Prop()
  value!: string

  @Prop()
  id?: string

  @Prop()
  name?: string

  @Prop({ default: true })
  checked!: boolean

  @Prop()
  textOn!: string

  @Prop()
  textOff!: string

  get isChecked(): boolean {
    return this.checked
  }

  set isChecked(value: boolean) {
    this.checked = value
    this.$emit('input', value)
  }

  private clickEvent(key: Event): void {
    this.$emit('click-event', key)
  }
}
