import { render, staticRenderFns } from "./ModalStudentReview.vue?vue&type=template&id=54d01fdc&scoped=true&lang=pug&"
import script from "./ModalStudentReview.vue?vue&type=script&lang=ts&"
export * from "./ModalStudentReview.vue?vue&type=script&lang=ts&"
import style0 from "./ModalStudentReview.vue?vue&type=style&index=0&id=54d01fdc&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54d01fdc",
  null
  
)

export default component.exports