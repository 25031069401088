































import { Component, Prop, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ImageRound from '@/components/atoms/ImageRound.vue'
import LabelBase from '@/components/atoms/LabelBase.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ToggleButton from '@/components/molecules/ToggleButton.vue'
import { GetStudentsResponseType } from '@/models/api/students'

@Component({
  components: {
    ColoredBox,
    ImageRound,
    LabelBase,
    ButtonBase,
    ToggleButton,
  },
})
export default class StudentInformation extends Vue {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  @Prop()
  student!: {
    userId: number
    name: string
    icon: string
    school: string
    grade: string
    typeCode: string
    point?: number
    status: object[]
    isAttended: boolean
  }
  @Prop()
  lesson!: number
  @Prop()
  enabled!: boolean

  // 各授業モードのリンク名 key: class_modes.code
  private readonly HISTORY_LEARNING_SITUATION = {
    TJ: 'TeacherLearningSituation',
    TK: 'TeacherTestLearningSituation',
    KS: 'TeacherSeminarLearningSituation',
    NY: 'TeacherEntranceLearningSituation',
  }

  private changeAttend(): void {
    Vue.prototype.$http.httpWithToken
      .patch(`/lessonUsers/${this.lesson}/attend`, {
        studentId: this.student.userId,
        isAttended: this.student.isAttended,
      })
      .catch((error: any) => {
        if (error.response.data.status === 422) {
          alert('他の授業に参加中の生徒は出席にできません。')
          this.student.isAttended = false
        } else {
          alert('出欠席の更新に失敗しました。')
          location.reload()
        }
      })
  }

  private async clickLearningSituation() {
    await this.setCookieStudentInfo()
    let pathName = this.HISTORY_LEARNING_SITUATION[this.student.typeCode]
    if (!pathName) {
      // 授業モード不明の場合は通常履歴へ飛ばす
      pathName = this.HISTORY_LEARNING_SITUATION['TJ']
    }
    this.$router.push({ name: pathName })
  }

  /**
   * 生徒情報を取得してCookieにセットする
   */
  private async setCookieStudentInfo() {
    const { data }: { data: GetStudentsResponseType } = await Vue.prototype.$http.httpWithToken.get(`/students`, {
      params: {
        userId: this.student.userId,
        branchId: this.branchId,
      },
    })
    // 生徒情報が正常に取得できない場合は履歴トップへ遷移
    if (data.count !== 1) {
      this.$router.push({ name: 'History' })
      return
    }
    const student = data.students[0]
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie.student = {
      userId: student.id,
      nickname: student.nickname,
      iconUrl: student.iconUrl,
      studentId: student.studentId,
      studentCode: student.studentCode,
      schoolName: student.schoolName,
      gradeName: student.gradeName,
    }
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  private checkClickStatus(status: any): string | null {
    if (status.onclick) {
      // 変数の指定があればその変数を渡す
      if (status.variable) {
        return status.onclick(status.variable)
      }
      return status.onclick()
    }
    return null
  }
}
