











































import { Component, Vue, Ref, Mixins, Watch } from 'vue-property-decorator'
import NotificationList from '@/components/organisms/NotificationList.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import StudentInformation from '@/components/organisms/StudentInformation.vue'
import ModalStudentReview from '@/components/organisms/ModalStudentReview.vue'
import ModalNotification from '@/components/organisms/ModalNotification.vue'
import LocalMoment from '@/components/atoms/LocalMoment.vue'
import moment from 'moment'

export type LessonStatus = 'start' | 'participate' | 'restart' | 'complete'

@Component({
  components: {
    NotificationList,
    SelectBase,
    StudentInformation,
    ModalStudentReview,
    ModalNotification,
  },
})
export default class Dashboard extends Mixins(LocalMoment) {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
  private notificationDatas: object[] = []
  private displayDate = ''
  private lessonDate = ''
  private lessonOptionDatas: object[] = []
  private selectedLesson: string | null = null
  private lessonId: string | null = null // 授業ID
  private lessonStatus: LessonStatus = 'start' // 授業ステータス
  private lessonCompleted = false
  private studentDatas: {
    userId: number
    name: string
    icon: string
    school: string
    grade: string
    typeCode: string
    gradeSort: string
    point?: number
    status: object[]
    isAttended: boolean
    sort: number
  }[] = []

  // ソート
  private orderTable = [0, 1, -1] // ソート順の定義 0: 無効, 1: 昇順, -1: 降順
  // orderTableのindexを格納する
  private sortBySchool = 0
  private sortByGrade = 0
  private sortByPoint = 0

  private studentReviewData = {
    content: '中1）数学) 1-1. 文字を使った式',
    face: '1',
    comment: '演習問題が全問正解だった！',
  }

  @Ref()
  modalNotification!: ModalNotification

  @Ref()
  modalStudentReview!: ModalStudentReview

  @Watch('selectedLesson')
  async onSelectedLessonChange(newLesson: string, oldLesson: string) {
    await Vue.prototype.$loading.start()
    const lessonInfo = newLesson.split(',')
    this.lessonId = lessonInfo[0]
    this.lessonStatus = lessonInfo[1] as LessonStatus
    await this.loadStudentList()
    await Vue.prototype.$loading.complete()
  }

  /**
   * 授業ステータスの表示名称
   */
  private statusName(): string {
    switch (this.lessonStatus) {
      case 'start':
        return '授業開始'
      case 'participate':
        return '授業参加'
      case 'restart':
        return '授業再開'
      case 'complete':
        return '授業終了'
    }
  }

  private showNotification(id: number): void {
    this.modalNotification.loadNotification(id)
  }

  private showStudentReview(params: any): void {
    this.modalStudentReview.loadLookback(params.userId, params.lessonId, params.classMode)
  }

  private async loadNotifications() {
    const params = ['role=teacher']
    Vue.prototype.$http.httpWithToken.get(`/notifications?${params.join('&')}`).then((res: any) => {
      this.notificationDatas = res.data.notifications.map(
        (notification: { id: number; title: string; startedAt: Date }) => {
          return {
            date: moment(notification.startedAt).format('YYYY/MM/DD'),
            title: notification.title,
            click: this.showNotification,
            variable: notification.id,
          }
        }
      )
    })
  }

  private onclickSortBySchool() {
    this.sortByGrade = 0
    this.sortByPoint = 0
    this.sortBySchool = (this.sortBySchool + 1) % this.orderTable.length
    this.sortStudentList()
  }
  private onclickSortByGrade() {
    this.sortBySchool = 0
    this.sortByPoint = 0
    this.sortByGrade = (this.sortByGrade + 1) % this.orderTable.length
    this.sortStudentList()
  }
  private onclickSortByPoint() {
    this.sortBySchool = 0
    this.sortByGrade = 0
    this.sortByPoint = (this.sortByPoint + 1) % this.orderTable.length
    this.sortStudentList()
  }

  private async loadInitialize() {
    await this.loadNotifications()
    await this.loadLessons()
  }

  private async loadLessons() {
    await Vue.prototype.$http.httpWithToken
      .get('/lessons', { params: { branchId: this.branchId, date: this.lessonDate } })
      .then((res: any) => {
        this.lessonOptionDatas = res.data.map(
          (lesson: { lessonId: number; className: string; lessonStatus: string }) => {
            return {
              text: lesson.className,
              value: `${lesson.lessonId},${lesson.lessonStatus}`,
            }
          }
        )
        if (this.lessonOptionDatas.length > 0) {
          this.selectedLesson = this.lessonOptionDatas[0]['value']
        }
      })
  }

  // 生徒一覧のソート
  private sortStudentList() {
    this.studentDatas.sort((studentData1: any, studentData2: any) => {
      if (this.sortBySchool != 0) {
        return studentData1.school.localeCompare(studentData2.school) * this.orderTable[this.sortBySchool]
      }
      if (this.sortByGrade != 0) {
        return (studentData1.gradeSort - studentData2.gradeSort) * this.orderTable[this.sortByGrade]
      }
      if (this.sortByPoint != 0) {
        if (!studentData1.point && !studentData2.point) return 0
        if (!studentData1.point) return 1
        if (!studentData2.point) return -1

        return (studentData1.point - studentData2.point) * this.orderTable[this.sortByPoint]
      }
      return studentData1.sort - studentData2.sort
    })
  }

  private async loadStudentList() {
    await Vue.prototype.$http.httpWithToken.get(`/lessonUsers/list/${this.lessonId}`).then((res: any) => {
      this.studentDatas = res.data.map(
        (
          user: {
            userId: number
            studentCode: string
            type: string
            typeCode: string
            point: number
            nickname: string
            grade: string
            gradeSort: number
            school: string
            hasLastLesson: boolean
            isAttended: boolean
          },
          index: number
        ) => {
          return {
            userId: user.userId,
            name: user.nickname,
            icon: '',
            school: user.school,
            point: user.point,
            grade: user.grade,
            typeCode: user.typeCode,
            gradeSort: user.gradeSort,
            isAttended: user.isAttended,
            status: this.studentStatusInfo(user),
            sort: index,
          }
        }
      )
      this.sortStudentList()
    })
  }

  private studentStatusInfo(user: any): any[] {
    const status: any[] = []
    if (user.type) {
      const isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
      const type = isV3 && user.type === 'AI' ? '通常' : user.type
      status.push({ type: 'alert', message: type })
    }

    if (user.homeworkNotSubmitted) {
      status.push({ type: 'information', message: '宿題：未提出' })
    }
    if (user.hasLastLesson) {
      if (user.lookbacks) {
        user.lookbacks.forEach((lookback: any) => {
          status.push({
            type: 'information',
            message: `前回コメント：${lookback.classModeName}`,
            onclick: this.showStudentReview,
            variable: { userId: user.userId, lessonId: lookback.lessonId, classMode: lookback.classMode },
          })
        })
      } else {
        status.push({ type: 'information', message: '前回コメント：なし' })
      }
    }
    return status
  }

  /**
   * 授業開始ボタン押下時の処理
   */
  private startLesson() {
    const params = [`branchId=${this.branchId}`, `lessonId=${this.lessonId}`]
    Vue.prototype.$http.httpWithToken.get(`/lessons/start?${params.join('&')}`).then(async (res: any) => {
      const reasons = res.data.reason
      if (reasons.length > 0) {
        let message = ''
        if (reasons.includes(1)) {
          res.data.inOtherLessonUsers.forEach((user: any) => {
            message += `${user.name}は${user.className}に参加中です\n`
          })
        }
        if (reasons.includes(2)) {
          message += '授業終了後30分が経過しています\n'
        }
        if (reasons.includes(3)) {
          message += '授業を開始した先生のみ授業を再開できます\n'
        }
        alert(message)
      } else {
        if (this.isV3) {
          window.location.href = `/teacher/v3/lesson/${this.lessonId}`
          return
        }
        window.location.href = `/teacher/lesson/${this.lessonId}`
      }
    })
  }

  private async mounted() {
    Vue.prototype.$loading.start()
    this.setDate()
    await this.loadInitialize()
    Vue.prototype.$loading.complete()
  }

  private setDate() {
    const today = this.today()
    this.displayDate = today.format('YYYY年MM月DD日')
    this.lessonDate = today.format('YYYY-MM-DD')
  }

  get teacherStatusUrl(): string {
    return `/teacher/status?lessonId=${this.lessonId}`
  }

  get supporterMemoUrl(): string {
    return `/teacher/memo?lessonId=${this.lessonId}`
  }
}
