















import { Component, Vue, Mixins } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import FaceRadio from '@/components/molecules/FaceRadio.vue'

@Component({
  components: {
    ModalBase,
    FaceRadio,
  },
})
export default class ModalStudentReview extends Mixins(ModalBaseMethod) {
  private className = ''
  private comment = ''
  private result = 1

  public async loadLookback(userId: number, lessonId: string, classMode: string) {
    Vue.prototype.$http.httpWithToken
      .get('/lessonUsers/lookBack', { params: { classMode: classMode, lessonId: lessonId, userId: userId } })
      .then((res: any) => {
        this.result = res.data.lookBackResult
        this.comment = res.data.lookBackComment
        this.className = res.data.className
        this.show()
      })
  }
}
